import React from "react";
import { getFirestore } from "@firebase/firestore";
import { getFunctions } from "@firebase/functions";
import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Firebase v9+
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
} from "reactfire";

const firebaseConfig = {
  apiKey: process.env.GATSBY_apiKey,
  authDomain: process.env.GATSBY_authDomain,
  databaseURL: process.env.GATSBY_databaseURL,
  projectId: process.env.GATSBY_projectId,
  storageBucket: process.env.GATSBY_storageBucket,
  messagingSenderId: process.env.GATSBY_messagingSenderId,
  appId: process.env.GATSBY_appId,
  measurementId: process.env.GATSBY_measurementId,
};

const app = initializeApp(firebaseConfig);

// Auth
const auth = getAuth(app);

// Firestore
const firestore = getFirestore(app);

// Cloud Functions
const functions = getFunctions(app);

// Storage
const storage = getStorage(app);

const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseComponents>{children}</FirebaseComponents>
    </FirebaseAppProvider>
  );
};

const FirebaseComponents = ({ children }: { children: React.ReactElement }) => {
  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FunctionsProvider sdk={functions}>
          <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
        </FunctionsProvider>
      </StorageProvider>
    </AuthProvider>
  );
};

export { FirebaseProvider, app, auth, firestore, functions, storage };
