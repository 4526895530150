import React from "react";

import { FirebaseProvider } from "./firebase.config";
import { QueryClientProvider } from '@tanstack/react-query';

import "./src/styles/global.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-datepicker/dist/react-datepicker.css";
// kendo theme
//import "@progress/kendo-theme-default/dist/all.css";
import "./src/styles/kendo-spike.scss";

import { queryClient } from './src/lib/queryClient';

import { sentryInit } from "./src/utils/sentry";

sentryInit();

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <FirebaseProvider>
      <QueryClientProvider client={queryClient}>
        {element}
      </QueryClientProvider>
    </FirebaseProvider>
  );
};
