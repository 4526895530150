import * as Sentry from "@sentry/gatsby";
import { User } from "firebase/auth";

const isDev = process.env.NODE_ENV === "development";
const isEnabled = !isDev;

const SENTRY_PUBLIC_DSN =
  "https://b3ec3ddfc1448618e6c390345960da8f@o4508009681387520.ingest.us.sentry.io/4508009683681280";

export const sentryInit = () => {
  if (isEnabled) {
    Sentry.init({
      dsn: SENTRY_PUBLIC_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 1.0,
      environment: process.env.NODE_ENV,
    });
  }
};

export const sendSentryMessage = (msg: string) => {
  isDev && console.log(msg);
  Sentry.captureMessage(msg);
};

export const sendSentryError = ({
  message,
  error,
}: {
  message: string;
  error?: unknown;
}) => {
  isDev && console.error(message, error);

  if (error) {
    const code =
      error instanceof Error && "code" in error
        ? error.code
        : "no code provided";
    const details =
      error instanceof Error && "details" in error
        ? error.details
        : "no details provided";
    const hint =
      error instanceof Error && "hint" in error
        ? error.hint
        : "no hint provided";
    const errorText = (error as Error)?.message ?? message;
    Sentry.captureMessage(errorText);
    Sentry.captureException(error as Error, {
      extra: {
        code,
        details,
        hint,
        message,
      },
    });
  } else {
    Sentry.captureMessage(message);
  }
};

export const setSentryUser = ({ uid, email }: User) => {
  Sentry.setUser({ id: uid, email: email ?? undefined });
};

export const addSentryBreadcrumb = ({
  category,
  message,
  data,
}: {
  category: string;
  message: string;
  data?: Record<string, unknown>;
}) => {
  Sentry.addBreadcrumb({
    category,
    message,
    data,
  });
};
