import { QueryClient } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Disable default of automatic retries of 3 times on Query error
      retry: false,
    },
  },
});

export { queryClient };
